import React from 'react'
import * as Data from '../data'
import {Iframe} from './iframe'

function safeParse(data) {
  try {
    return JSON.parse(data) || {}
  } catch (error) {
    return {}
  }
}

export function SolidPlayngoLauncher() {
  const dispatch = Data.useDispatch()

  React.useEffect(() => {
    function handleMessage(event) {
      const action = safeParse(event.data)

      switch (action.event) {
        case 'exit': {
          dispatch(Data.closeGame())
          break
        }
        case 'reloadGame': {
          window.location.reload()
          break
        }
        default: {
          // eslint-disable-next-line no-console
          console.log(event)
        }
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return (
    <Iframe />
  )
  
}

// for @loadable/components
export default SolidPlayngoLauncher
